<div class="op-baseline--header"
    [class.op-baseline_tab]="!showActionBar">
  <div
    class="op-baseline--enterprise-title"
    *ngIf="eeShowBanners">
    <span class="">{{ text.baseline_comparison }}</span>
    <span class="spot-icon spot-icon_enterprise-addons"></span>
  </div>
  <span class="spot-body-small">{{ eeShowBanners ? text.enterprise_header_description : text.header_description }}</span>
  <a
    *ngIf="eeShowBanners"
    [attr.href]="text.moreInfoLink"
    class="op-baseline--info-button spot-body-small"
    target=â€_blankâ€>
    {{ text.more_info_text }}
    <span class="spot-icon spot-icon_external-link"></span>
    </a>
</div>
<form
  *ngIf="visible && (nonWorkingDays$ | async); else loadingTemplate"
  (submit)="onSubmit($event)"
  class="spot-container op-baseline--body"
  [class.op-baseline--body_ranged]="selectedFilter === 'betweenTwoSpecificDates'"
  [class.op-baseline_tab]="!showActionBar"
>


  <spot-form-field
    [label]="text.show_changes_since">

    <div slot="input">
      <select
        name="op-baseline-filter"
        class="op-baseline--filter"
        (change)="filterChange($event.target.value)"
      >
        <option [textContent]="text.drop_down_none_option"
                [selected]="!filterSelected"
                [value]="'-'"></option>
        <option
          *ngFor="let availableValue of baselineAvailableValues"
          [value]="availableValue.value"
          [selected]="availableValue.value === selectedFilter"
          [disabled]="eeShowBanners && availableValue.value !== 'oneDayAgo'"
        >
          {{availableValue.title}}
        </option>
      </select>

    </div>
    <span slot="description" *ngIf="mappedSelectedDate">  {{ mappedSelectedDate }} </span>
  </spot-form-field>

  <spot-form-field
    [label]="text.date"
    *ngIf="selectedFilter === 'aSpecificDate'"
    class="op-baseline--date">

    <input
      slot="input"
      name="op-baseline-date"
      [value]="selectedDates[0] || ''"
      (input)="dateChange([$event.target.value])"
      type="text"
      class="op-baseline--date-input"/>
  </spot-form-field>

  <spot-form-field
    *ngIf="selectedFilter && selectedFilter !== 'betweenTwoSpecificDates'"
    [label]="text.time"
    class="op-baseline--time">

    <input
      slot="input"
      name="op-baseline-time"
      [value]="selectedTimes[0]"
      (change)="timesChange([$event.target.value])"
      type="time"
      class="op-baseline--time-input"/>

    <span
      slot="input"
      class="op-baseline--time-help spot-body-small"
        [attr.title]="text.help_description"
        [textContent]="mappedOffset(selectedOffsets[0])"
    ></span>

    <span
      *ngIf="userOffset !== selectedOffsets[0]"
      slot="description"
      [textContent]="text.time_description(0)"></span>
  </spot-form-field>

  <div
    *ngIf="selectedFilter === 'betweenTwoSpecificDates'"
    class="op-baseline--range-container"
    >

    <spot-form-field
      [label]="text.from"
      class="op-baseline--from-date">

      <input
        slot="input"
        name="op-baseline-from-date"
        [value]="selectedDates[0] || ''"
        (input)="dateChange([$event.target.value, selectedDates[1] || ''])"
        type="text"
        class="op-baseline--date-input"/>
      <button
        slot="action"
        type="button"
        class="spot-link"
        (click)="setToday('from')"
        [textContent]="text.today">
      </button>
    </spot-form-field>

    <spot-form-field
      [label]="text.to"
      class="op-baseline--date">

      <input
        slot="input"
        name="op-baseline-to-date"
        [value]="selectedDates[1] || ''"
        (input)="dateChange([selectedDates[0] || '', $event.target.value])"
        type="text"
        class="op-baseline--date-input"/>
      <button
        slot="action"
        type="button"
        class="spot-link"
        (click)="setToday('to')"
        [textContent]="text.today">
      </button>
    </spot-form-field>

    <spot-form-field
      class="op-baseline--time">

      <input
        slot="input"
        name="op-baseline-from-time"
        [value]="selectedTimes[0] || ''"
        (change)="timesChange([$event.target.value, selectedTimes[1]])"
        type="time"
        class="op-baseline--time-input"/>

      <spot-tooltip
        slot="input"
        class="op-baseline--time-tooltip op-baseline--time-help spot-body-small"
        [alignment]="tooltipPosition"
        [disabled]="false"
        [light]="true">
        <span slot="trigger" class="op-baseline--time-zone" [textContent]="mappedOffset(selectedOffsets[0])"></span>
        <span slot="body" class="spot-body-small op-baseline--time-tooltip-body">{{ text.help_description }}</span>
      </spot-tooltip>


      <span
        *ngIf="userOffset !== selectedOffsets[0]"
        slot="description" [textContent]="text.time_description(0)"></span>
    </spot-form-field>

    <spot-form-field
      class="op-baseline--time">

      <input
        slot="input"
        name="op-baseline-to-time"
        [value]="selectedTimes[1] || ''"
        (change)="timesChange([selectedTimes[0], $event.target.value])"
        type="time"
        class="op-baseline--time-input"/>

      <spot-tooltip
        slot="input"
        class="op-baseline--time-tooltip op-baseline--time-help spot-body-small"
        [alignment]="tooltipPosition"
        [disabled]="false"
        [light]="true">
        <span slot="trigger" class="op-baseline--time-zone" [textContent]="mappedOffset(selectedOffsets[1])"></span>
        <span slot="body" class="spot-body-small op-baseline--time-tooltip-body">{{ text.help_description }}</span>
      </spot-tooltip>


      <span
        *ngIf="userOffset !== selectedOffsets[1]"
        slot="description" [textContent]="text.time_description(1)"></span>
    </spot-form-field>
  </div>

  <op-datepicker-sheet
    class="op-baseline--datepicker"
    *ngIf="selectedFilter === 'aSpecificDate' || selectedFilter === 'betweenTwoSpecificDates'"
    [dates]="selectedFilter === 'betweenTwoSpecificDates' ? selectedDates : [selectedDates[0]]"
    [mode]="selectedFilter === 'betweenTwoSpecificDates' ? 'range' : 'single'"
    [isDisabled]="futureDateComparer()"
    (datesSelected)="selectedDates = $event"
    >
  </op-datepicker-sheet>

  <div class="spot-action-bar"
      *ngIf="showActionBar">

    <div class="spot-action-bar--right">
      <button
        class="button spot-action-bar--action"
        [disabled]="!selectedFilter"
        type="button"
        (click)="resetSelection()"
      >
        {{ text.clear }}
      </button>
      <button
        class="button -primary spot-action-bar--action"
      >
        {{ text.apply }}
      </button>
    </div>
  </div>
</form>

<ng-template #loadingTemplate>
  <op-baseline-loading></op-baseline-loading>
</ng-template>
